import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"


const AksaraPackageTemplate = ({pageContext: post}) => {
    // const post = data.allSitePage.edges[0].node.pageContext.post // "jsonData" sesuai dengan nama query di bawah
    // const post = data.allSitePage.edges[0].node.pageContext.post // "jsonData" sesuai dengan nama query di bawah
    // const post = data.allSitePage.edges[0].node.pageContext.post // "jsonData" sesuai dengan nama query di bawah

    // const buildMeta = [{
    //     name: "go-import",
    //     content: `${post.pkg_url} git ${post.repo_url}`
    // },
    // {
    //     name: "go-source",
    //     content:`${post.pkg_url} ${post.repo_url} ${post.repo_url}/tree/${post.prod_branch}{/dir} ${post.repo_url}/blob/${post.prod_branch}{/dir}/{file}#L{line}`
    // }
    // ]
    return (
        <Layout>
            <p>Test</p>
            {/*<Seo title="Aksara Package " meta={buildMeta} />*/}
            {/*<h1>{post.title}</h1>*/}
            {/*<p>How to install</p>*/}
            {/*<code>go get -u {post.pkg_url}</code>*/}
        </Layout>
    )
}

// export const query = graphql`
//   query($path: String!) {
//     allSitePage(filter: {path: {eq: $path}}) {
//       edges {
//           node {
//             pageContext
//             path
//           }
//       }
//     }
//   }
// `

export default AksaraPackageTemplate
